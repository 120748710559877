import styled from 'styled-components';

export const ItemOption = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  color: #403e3e;
  text-align: left;
  cursor: pointer;
  transition: opacity 0.25s;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }

  & > div {
    margin-left: 16px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Input = styled.input`
  border-radius: 8px;
  padding: 20px 16px;
  width: 100%;
  color: #403e3e;
  text-align: left;
  border: none;
  outline: none;
  font-size: 16px;
`;

export const DividerInput = styled.div`
  margin-bottom: 16px;
`;

export const TextArea = styled.textarea`
  border-radius: 8px;
  padding: 20px 16px;
  width: 100%;
  color: #403e3e;
  text-align: left;
  border: none;
  outline: none;
  font-size: 16px;
  min-height: 120px;
`;
