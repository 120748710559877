import React, { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import validator from 'validator';

import IconButton from '@material-ui/core/IconButton';
import DesignServicesIcon from '@material-ui/icons/BrushOutlined';
import CodeIcon from '@material-ui/icons/CodeOutlined';
import LightbulbIcon from '@material-ui/icons/EmojiObjectsOutlined';
import LightModeIcon from '@material-ui/icons/WbSunnyOutlined';
import Brightness5Icon from '@material-ui/icons/Brightness5Outlined';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHighOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './App.css';
import { ItemOption, Input, TextArea, DividerInput } from './styes';

const defaultBody = {
  type: '',
  size: '',
  name: '',
  phone: '',
  email: '',
  company: '',
  description: '',
};

// const defaultTouched = {
//   name: false,
//   phone: false,
//   email: false,
//   company: false,
//   description: false,
// };

function App() {
  const [step, setStep] = useState(1);
  const [body, setBody] = useState({...defaultBody});
  // const [touched, setTouched] = useState({...defaultTouched});
  const [showErrors, setShowErrors] = useState(false);
  const canvas = useRef(null);

  useEffect(() => {
    if (canvas && canvas.current) {
      console.log('CANVAS', canvas);
      const c = canvas.current;
      var w = c.width = window.innerWidth,
          h = c.height = window.innerHeight,
          ctx = c.getContext( '2d' ),

          minDist = 10,
          maxDist = 30,
          initialWidth = 10,
          maxLines = 100,
          initialLines = 4,
          speed = 5,

          lines = [],
          frame = 0,
          timeSinceLast = 0,

          dirs = [
         // straight x, y velocity
            [ 0, 1 ],
            [ 1, 0 ],
            [ 0, -1 ],
          	[ -1, 0 ],
         // diagonals, 0.7 = sin(PI/4) = cos(PI/4)
            [ .7, .7 ],
            [ .7, -.7 ],
            [ -.7, .7 ],
            [ -.7, -.7]
          ],
          starter = { // starting parent line, just a pseudo line

            x: w / 2,
            y: h / 2,
            vx: 0,
            vy: 0,
            width: initialWidth
          };

      function init() {

        lines.length = 0;

        for( var i = 0; i < initialLines; ++i )
          lines.push( new Line( starter ) );

        ctx.fillStyle = '#222';
        ctx.fillRect( 0, 0, w, h );

        // if you want a cookie ;)
        // ctx.lineCap = 'round';
      }
      function getColor( x ) {
        return '#ff3331';
        // return 'hsl( hue, 80%, 50% )'.replace(
        // 	'hue', x / w * 360 + frame
        // );
      }
      function anim() {

        window.requestAnimationFrame( anim );

        ++frame;

        ctx.shadowBlur = 0;
        ctx.fillStyle = 'rgba(0,0,0,.02)';
        ctx.fillRect( 0, 0, w, h );
        ctx.shadowBlur = .5;

        for( var i = 0; i < lines.length; ++i )

          if( lines[ i ].step() ) { // if true it's dead

            lines.splice( i, 1 );
            --i;

          }

        // spawn new

        ++timeSinceLast

        if( lines.length < maxLines && timeSinceLast > 10 && Math.random() < .5 ) {

          timeSinceLast = 0;

          lines.push( new Line( starter ) );

          // cover the middle;
          ctx.fillStyle = ctx.shadowColor = getColor( starter.x );
          ctx.beginPath();
          ctx.arc( starter.x, starter.y, initialWidth, 0, Math.PI * 2 );
          ctx.fill();
        }
      }

      function Line( parent ) {

        this.x = parent.x | 0;
        this.y = parent.y | 0;
        this.width = parent.width / 1.25;

        do {

          var dir = dirs[ ( Math.random() * dirs.length ) |0 ];
          this.vx = dir[ 0 ];
          this.vy = dir[ 1 ];

        } while (
          ( this.vx === -parent.vx && this.vy === -parent.vy ) || ( this.vx === parent.vx && this.vy === parent.vy) );

        this.vx *= speed;
        this.vy *= speed;

        this.dist = ( Math.random() * ( maxDist - minDist ) + minDist );

      }
      Line.prototype.step = function() {

        var dead = false;

        var prevX = this.x,
            prevY = this.y;

        this.x += this.vx;
        this.y += this.vy;

        --this.dist;

        // kill if out of screen
        if(this.x < 0 || this.x > w || this.y < 0 || this.y > h) dead = true;

        // make children :D
        if (this.dist <= 0 && this.width > 1) {

          // keep yo self, sometimes
          this.dist = Math.random() * (maxDist - minDist) + minDist;

          // add 2 children
          if(lines.length < maxLines) lines.push(new Line(this));
          if(lines.length < maxLines && Math.random() < 0.5) lines.push(new Line(this));

          // kill the poor thing
          if(Math.random() < 0.2) dead = true;
        }

        ctx.strokeStyle = ctx.shadowColor = getColor(this.x);
        ctx.beginPath();
        ctx.lineWidth = this.width;
        ctx.moveTo(this.x, this.y);
        ctx.lineTo(prevX, prevY);
        ctx.stroke();

        if (dead) return true;
      }

      init();
      anim();

      window.addEventListener('resize', () => {
        w = c.width = window.innerWidth;
        h = c.height = window.innerHeight;
        starter.x = w / 2;
        starter.y = h / 2;

        init();
      });
    }
  }, [canvas]);

  const nameError = !body.name;
  const phoneError = !body.phone || !validator.isMobilePhone(body.phone);
  const emailError = !body.email || !validator.isEmail(body.email);
  const companyError = !body.company;
  const descriptionError = !body.description;

  const handleSendForm = async () => {
    try {
      if (nameError || emailError || phoneError || companyError || descriptionError) {
        setShowErrors(true);
        return;
      }
      await Axios
        .post('https://staging-dot-suppdesk-271403.appspot.com/api/tickets/anonymous',
        {
          description: `Prospecto: ${body.name} - ${body.email} - ${body.phone}.\nSolicitud: ${body.type} - ${body.size}.\n${body.description}`,
          reportedDate: new Date(),
          shortName: `Solicitud de información: ${body.company} - ${body.type}`,
          clientData: {
            name: body.name,
            phone: body.phone,
            email: '',
          },
          token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbnN0YW5jZUlkIjo3LCJpYXQiOjE2MTYwMDU2ODR9.eqQpwtb4Bm3XnsTM2G6brLZLL8AJI1HybFkpVGPuRQM',
        },
      );
      setStep(4);
    } catch (e) {

    }
  }

  const handleSetBody = (key, value) => {
    setBody(_body => ({
      ..._body,
      [key]: value,
    }));
  };

  return (
    <div className="App">
      <canvas ref={canvas}></canvas>
      <div className="container">
        {step === 1 && (
          <React.Fragment>
            <img src="./logo.png" alt="Logo InnovaApps" className="logo" />
            <h2>¿En qué podemos ayudarte?</h2>
            <ItemOption
              onClick={() => {
                handleSetBody('type', 'Desarrollo de software')
                setStep(2);
              }}
            >
              <CodeIcon />
              <div>
                Desarrollo de software
              </div>
            </ItemOption>
            <ItemOption
              onClick={() => {
                handleSetBody('type', 'Diseño de interfaces')
                setStep(2);
              }}
            >
              <DesignServicesIcon />
              <div>
                Diseño de interfaces
              </div>
            </ItemOption>
            <ItemOption
              onClick={() => {
                handleSetBody('type', 'Solución completa')
                setStep(2);
              }}
            >
              <LightbulbIcon />
              <div>
                Solución completa
              </div>
            </ItemOption>
          </React.Fragment>
        )}
        {step === 2 && (
          <React.Fragment>
            <div className="logo-section">
              <IconButton onClick={() => setStep(2)}>
                <ArrowBackIcon style={{ color: '#ffffff' }} />
              </IconButton>
              <img src="./logo.png" alt="Logo InnovaApps" className="logo" />
            </div>
            <h2>¿De qué tamaño es tu proyecto?</h2>
            <ItemOption
              onClick={() => {
                handleSetBody('size', 'Chico');
                setStep(3);
              }}
            >
              <LightModeIcon />
              <div>
                Chico
              </div>
            </ItemOption>
            <ItemOption
              onClick={() => {
                handleSetBody('size', 'Mediano');
                setStep(3);
              }}
            >
              <Brightness5Icon />
              <div>
                Mediano
              </div>
            </ItemOption>
            <ItemOption
              onClick={() => {
                handleSetBody('size', 'Grande');
                setStep(3);
              }}
            >
              <BrightnessHighIcon />
              <div>
                Grande
              </div>
            </ItemOption>

          </React.Fragment>
        )}
        {step === 3 && (
          <React.Fragment>
            <div className="logo-section">
              <IconButton onClick={() => setStep(1)}>
                <ArrowBackIcon style={{ color: '#ffffff' }} />
              </IconButton>
              <img src="./logo.png" alt="Logo InnovaApps" className="logo" />
            </div>
            <h2>¿Dónde te podemos contactar?</h2>
            <Input placeholder="Tu nombre" type="text" maxLength={255} value={body.name} onChange={e => handleSetBody('name', e.target.value)} />
            {showErrors && nameError && <div className="error">Ingrese un nombre</div>}
            <DividerInput />
            <Input placeholder="Tu número de teléfono" type="tel" maxLength={16} value={body.phone} onChange={e => handleSetBody('phone', e.target.value)} />
            {showErrors && phoneError && <div className="error">Ingrese un número válido</div>}
            <DividerInput />
            <Input placeholder="Tu correo" type="email" maxLength={255} value={body.email} onChange={e => handleSetBody('email', e.target.value)} />
            {showErrors && emailError && <div className="error">Ingrese un correo válido</div>}
            <DividerInput />
            <Input placeholder="El nombre de tu empresa" type="text" maxLength={255} value={body.company} onChange={e => handleSetBody('company', e.target.value)} />
            {showErrors && companyError && <div className="error">Ingrese el nombre de su empresa</div>}
            <DividerInput />
            <TextArea placeholder="Cuéntanos los problemas en tu empresa y tus ideas" type="text" maxLength={10000} value={body.description} onChange={e => handleSetBody('description', e.target.value)} />
            {showErrors && descriptionError && <div className="error">Ingrese algunos detalles</div>}
            <DividerInput />
            <button className="send-button" onClick={handleSendForm}>
              ¡Contáctanos!
            </button>
          </React.Fragment>
        )}
        {step === 4 && (
          <React.Fragment>
            <img src="./logo.png" alt="Logo InnovaApps" className="logo" />
            <h2>¡Gracias por contactarnos!</h2>
            <div>
              Nos comunicaremos contigo lo antes posible
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default App;
